const languages = new Set([
 'adoc',
 'apacheconf',
 'arm',
 'as',
 'asc',
 'atom',
 'bat',
 'bf',
 'bind',
 'c++',
 'capnp',
 'cc',
 'clj',
 'cls',
 'cmake.in',
 'cmd',
 'coffee',
 'console',
 'cr',
 'craftcms',
 'crm',
 'cs',
 'cson',
 'cts',
 'cxx',
 'dfm',
 'docker',
 'dst',
 'erl',
 'f90',
 'f95',
 'fs',
 'gawk',
 'gemspec',
 'gms',
 'golang',
 'gololang',
 'gss',
 'gyp',
 'h',
 'h++',
 'hbs',
 'hh',
 'hpp',
 'hs',
 'html',
 'html.handlebars',
 'html.hbs',
 'https',
 'hx',
 'hxx',
 'hylang',
 'i7',
 'iced',
 'ino',
 'instances',
 'irb',
 'jinja',
 'js',
 'jsp',
 'jsx',
 'julia-repl',
 'kdb',
 'kt',
 'lassoscript',
 'ls',
 'ls',
 'mak',
 'make',
 'mawk',
 'md',
 'mipsasm',
 'mk',
 'mkd',
 'mkdown',
 'ml',
 'ml',
 'mm',
 'mma',
 'moon',
 'mts',
 'nawk',
 'nc',
 'nginxconf',
 'nimrod',
 'objc',
 'obj-c',
 'obj-c++',
 'objective-c++',
 'osascript',
 'pas',
 'pascal',
 'patch',
 'pcmk',
 'pf.conf',
 'pl',
 'plist',
 'pm',
 'podspec',
 'postgres',
 'postgresql',
 'pp',
 'ps',
 'ps1',
 'py',
 'pycon',
 'rb',
 're',
 'rs',
 'rss',
 'sas',
 'scad',
 'sci',
 'sh',
 'st',
 'stanfuncs',
 'step',
 'stp',
 'styl',
 'svg',
 'tao',
 'text',
 'thor',
 'tk',
 'toml',
 'ts',
 'tsx',
 'txt',
 'v',
 'vb',
 'vbs',
 'wl',
 'x++',
 'xhtml',
 'xjb',
 'xls',
 'xlsx',
 'xpath',
 'xq',
 'xsd',
 'xsl',
 'yaml',
 'zep',
 'zone',
 'zsh',
 '1c',
 'abnf',
 'accesslog',
 'actionscript',
 'ada',
 'angelscript',
 'apache',
 'applescript',
 'arcade',
 'arduino',
 'armasm',
 'asciidoc',
 'aspectj',
 'autohotkey',
 'autoit',
 'avrasm',
 'awk',
 'axapta',
 'bash',
 'basic',
 'bnf',
 'brainfuck',
 'c',
 'cal',
 'capnproto',
 'clojure',
 'cmake',
 'coffeescript',
 'coq',
 'cos',
 'cpp',
 'crmsh',
 'crystal',
 'csharp',
 'csp',
 'css',
 'd',
 'dart',
 'diff',
 'django',
 'dns',
 'dockerfile',
 'dos',
 'dpr',
 'dsconfig',
 'dts',
 'dust',
 'ebnf',
 'elixir',
 'elm',
 'erlang',
 'excel',
 'fix',
 'fortran',
 'fsharp',
 'gams',
 'gauss',
 'gcode',
 'gherkin',
 'glsl',
 'go',
 'golo',
 'gradle',
 'graph',
 'graphql',
 'groovy',
 'haml',
 'handlebars',
 'haskell',
 'haxe',
 'http',
 'hy',
 'inform7',
 'ini',
 'irpf90',
 'java',
 'javascript',
 'json',
 'julia',
 'k',
 'kotlin',
 'lasso',
 'ldif',
 'leaf',
 'less',
 'lisp',
 'livecodeserver',
 'livescript',
 'lua',
 'makefile',
 'markdown',
 'mathematica',
 'matlab',
 'maxima',
 'mel',
 'mercury',
 'mips',
 'mizar',
 'mojolicious',
 'monkey',
 'moonscript',
 'n1ql',
 'nginx',
 'nim',
 'nix',
 'nsis',
 'objectivec',
 'ocaml',
 'openscad',
 'oxygene',
 'p21',
 'parser3',
 'perl',
 'pf',
 'pgsql',
 'php',
 'plaintext',
 'pony',
 'powershell',
 'processing',
 'profile',
 'prolog',
 'properties',
 'protobuf',
 'puppet',
 'python',
 'python-repl',
 'qml',
 'r',
 'reasonml',
 'rib',
 'rsl',
 'ruby',
 'ruleslanguage',
 'rust',
 'SAS',
 'scala' ,
 'scheme',
 'scilab',
 'scss',
 'shell',
 'smali',
 'smalltalk',
 'sml',
 'sql',
 'stan',
 'stata',
 'stylus',
 'subunit',
 'swift',
 'tap',
 'tcl',
 'tex',
 'thrift',
 'tp',
 'twig',
 'typescript',
 'vala',
 'vbnet',
 'vbscript',
 'verilog',
 'vhdl',
 'vim',
 'x86asm',
 'xl',
 'xml',
 'xquery',
 'yml',
 'zephir',
]);

const langSubset = [
  'python',
  'javascript',
  'java',
  'go',
  'bash',
  'c',
  'cpp',
  'csharp',
  'css',
  'diff',
  'graphql',
  'json',
  'kotlin',
  'less',
  'lua',
  'makefile',
  'markdown',
  'objectivec',
  'perl',
  'php',
  'php-template',
  'plaintext',
  'python-repl',
  'r',
  'ruby',
  'rust',
  'scss',
  'shell',
  'sql',
  'swift',
  'typescript',
  'vbnet',
  'wasm',
  'xml',
  'yaml',
];

export { languages, langSubset };