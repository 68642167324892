/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import cleanupPreset from '~/utils/cleanupPreset.js';
import { useRecoilValue, useRecoilState } from 'recoil';
import EditPresetDialog from '../../Endpoints/EditPresetDialog';
import EndpointItems from './EndpointItems';
import PresetItem from './PresetItem.jsx';
import PresetItems from './PresetItems';
import PresetItems2 from './PresetItems2';
import { Trash2 } from 'lucide-react';
import FileUpload from './FileUpload';
import getIcon from '~/utils/getIcon';
import getDefaultConversation from '~/utils/getDefaultConversation';
import { useDeletePresetMutation, useCreatePresetMutation } from '@librechat/data-provider';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DialogTemplate,
  Dialog,
  DialogTrigger
} from '../../ui/';
import { cn } from '~/utils/';

import store from '~/store';

export default function NewConversationMenu({ onValueChange }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showPresets, setShowPresets] = useState(true);
  const [showEndpoints, setShowEndpoints] = useState(true);
  const [presetModelVisible, setPresetModelVisible] = useState(false);
  const [preset, setPreset] = useState(false);
  const [conversation, setConversation] = useRecoilState(store.conversation) || {};
  const [messages, setMessages] = useRecoilState(store.messages);
  const availableEndpoints = useRecoilValue(store.availableEndpoints);
  const endpointsConfig = useRecoilValue(store.endpointsConfig);
  // const [presets, setPresets] = useRecoilState(store.presets);
  const [presets, setPresets] = useState([]);
  const [presets2, setPresets2] = useRecoilState(store.presets);

  // const conversation = useRecoilValue(store.conversation) || {};
  const { endpoint, conversationId } = conversation;
  const { newConversation } = store.useConversation();

  const deletePresetsMutation = useDeletePresetMutation();
  const createPresetMutation = useCreatePresetMutation();

  // const importPreset = (jsonData) => {
  //   createPresetMutation.mutate(
  //       { ...jsonData },
  //     {
  //       onSuccess: (data) => {
  //         setPresets(data);
  //         console.log("GPT3",data)
  //       },
  //       onError: (error) => {
  //         console.error('Error uploading the preset:', error);
  //         console.log("GPT3",error)
  //       }
  //     }
  //   );
  // };

  // const importPreset2 = (jsonData) => {
  //   createPresetMutation.mutate(
  //     { ...jsonData },
  //     {
  //       onSuccess: (data) => {
  //         setPresets2(data);
  //         console.log("GPT4",data)
  //       },
  //       onError: (error) => {
  //         console.error('Error uploading the preset:', error);
  //       }
  //     }
  //   );
  // };


  useEffect(() => {
    const JsonData = [{
      "_id": "64b689fcc32cb56f6061b6ca",
      "presetId": "da689c86-6f09-43bf-bc2f-025b291640d3",
      "user": "64ae98b45a50ae8c20799a0d",
      "__v": 0,
      "agentOptions": null,
      "chatGptLabel": "AyoChat",
      "context": null,
      "createdAt": "2023-07-18T12:47:56.901Z",
      "endpoint": "openAI",
      "examples": [],
      "frequency_penalty": 0,
      "jailbreak": false,
      "maxOutputTokens": 1024,
      "model": "gpt-4",
      "modelLabel": null,
      "presence_penalty": 0,
      "promptPrefix": "AyoChat is a powerful chatbot developed by OpenAI. To enhance its capabilities, we're planning to integrate the Ayozat knowledge base into AyoChat. Ayozat is a deep technology company that offers a layered protocol product, Ayozat™ TLC, which powers various markets and ecosystems in the media, finance, iGaming, and governmental sectors.\n\nAyozat TLC is a robust platform that powers and distributes over 250 TV channels on satellite, cable, and online, broadcasts over 15,000 live sporting events a year, and powers payments, blockchains, and stock market solutions. It is designed to be sustainable, self-reliant, and extremely fast, as well as being backward, current, and forward technology compatible.\n\nThe Ayozat TLC stack includes an Artificial Intelligence Core, Cloud Hosting Layer, Secure Data Storage Layer, Application Layer, Content Delivery Network Layer, Consumer Payment Layer, Code Framework Layer, Blockchain Layer, Ingestion Transmission Layer, Global Private Network Layer, DevOps Infrastructure, Satellite, Terrestrial & Cable Crossover, and is Web1, Web2, Web3, WebX & Metaverse Ready.\n\nIn addition to the TLC stack, Ayozat offers a comprehensive application product portfolio, including Ayotising (Advertising Technology), Ayolytics (Business Analytics suite), OTT Video and TV streaming platform, Compression Technology, Digital Asset eXchange Software platform, Defence Supplier, Trading Platform, ZatLive, Virtual Data Centres, Re-stream Platform, News and Magazines, Ayozat.com, Ayozat TV, and AyoDax.\n\nBy integrating the Ayozat knowledge base into AyoChat, we aim to provide users with a more comprehensive and efficient chatbot experience.",
      "systemMessage": null,
      "temperature": 0.2,
      "title": "AyoChat",
      "toneStyle": null,
      "topK": 40,
      "topP": 0.95,
      "top_p": 1,
      "updatedAt": "2023-07-18T12:47:56.901Z"
    },
    {
      "_id": "64b689fcc32cb56f6061b6ce",
      "presetId": "3414d1c3-d5b8-4185-85bd-5b1f0d6d22c1",
      "user": "64ae98b45a50ae8c20799a0d",
      "__v": 0,
      "agentOptions": null,
      "chatGptLabel": "AyoChat",
      "context": null,
      "createdAt": "2023-07-18T12:47:56.911Z",
      "endpoint": "openAI",
      "examples": [],
      "frequency_penalty": 0,
      "jailbreak": false,
      "maxOutputTokens": 1024,
      "model": "gpt-3.5-turbo-16k",
      "modelLabel": null,
      "presence_penalty": 0,
      "promptPrefix": "AyoChat is a powerful chatbot developed by OpenAI. To enhance its capabilities, we're planning to integrate the Ayozat knowledge base into AyoChat. Ayozat is a deep technology company that offers a layered protocol product, Ayozat™ TLC, which powers various markets and ecosystems in the media, finance, iGaming, and governmental sectors.\n\nAyozat TLC is a robust platform that powers and distributes over 250 TV channels on satellite, cable, and online, broadcasts over 15,000 live sporting events a year, and powers payments, blockchains, and stock market solutions. It is designed to be sustainable, self-reliant, and extremely fast, as well as being backward, current, and forward technology compatible.\n\nThe Ayozat TLC stack includes an Artificial Intelligence Core, Cloud Hosting Layer, Secure Data Storage Layer, Application Layer, Content Delivery Network Layer, Consumer Payment Layer, Code Framework Layer, Blockchain Layer, Ingestion Transmission Layer, Global Private Network Layer, DevOps Infrastructure, Satellite, Terrestrial & Cable Crossover, and is Web1, Web2, Web3, WebX & Metaverse Ready.\n\nIn addition to the TLC stack, Ayozat offers a comprehensive application product portfolio, including Ayotising (Advertising Technology), Ayolytics (Business Analytics suite), OTT Video and TV streaming platform, Compression Technology, Digital Asset eXchange Software platform, Defence Supplier, Trading Platform, ZatLive, Virtual Data Centres, Re-stream Platform, News and Magazines, Ayozat.com, Ayozat TV, and AyoDax.\n\nBy integrating the Ayozat knowledge base into AyoChat, we aim to provide users with a more comprehensive and efficient chatbot experience.",
      "systemMessage": null,
      "temperature": 0.2,
      "title": "AyoChat",
      "toneStyle": null,
      "topK": 40,
      "topP": 0.95,
      "top_p": 1,
      "updatedAt": "2023-07-18T12:47:56.911Z"
    }];
    setPresets(JsonData);

    //  onFileSelected(JsonData);
    //  onFileSelected2(JsonData2);
  }, []);

  const onFileSelected = (jsonData) => {
    const jsonPreset = { ...cleanupPreset({ preset: jsonData, endpointsConfig }), presetId: null };
    importPreset(jsonPreset);
  };

  const onFileSelected2 = (jsonData) => {
    const jsonPreset = { ...cleanupPreset({ preset: jsonData, endpointsConfig }), presetId: null };
    importPreset2(jsonPreset);
  };

  // update the default model when availableModels changes
  // typically, availableModels changes => modelsFilter or customGPTModels changes
  useEffect(() => {
    const isInvalidConversation = !availableEndpoints.find((e) => e === endpoint);
    if (conversationId == 'new' && isInvalidConversation) {
      newConversation();
    }
  }, [availableEndpoints]);

  // save selected model to localStorage
  useEffect(() => {
    if (endpoint) {
      const lastSelectedModel = JSON.parse(localStorage.getItem('lastSelectedModel')) || {};
      localStorage.setItem(
        'lastSelectedModel',
        JSON.stringify({ ...lastSelectedModel, [endpoint]: conversation.model })
      );
      localStorage.setItem('lastConversationSetup', JSON.stringify(conversation));
    }

    if (endpoint === 'bingAI') {
      const lastBingSettings = JSON.parse(localStorage.getItem('lastBingSettings')) || {};
      const { jailbreak, toneStyle } = conversation;
      localStorage.setItem(
        'lastBingSettings',
        JSON.stringify({ ...lastBingSettings, jailbreak, toneStyle })
      );
    }
  }, [conversation]);

  // set the current model
  const onSelectEndpoint = (newEndpoint) => {
    setMenuOpen(false);
    if (!newEndpoint) {
      return;
    } else {
      newConversation({}, { endpoint: newEndpoint });
    }
  };

  // set the current model
  const onSelectPreset = (newPreset) => {
    setMenuOpen(false);
    console.log("onSelectPreset");
    onValueChange(false);
    console.log("onSelectPreset======");

    if (endpoint === 'gptPlugins' && newPreset?.endpoint === 'gptPlugins') {
      const currentConvo = getDefaultConversation({
        conversation,
        endpointsConfig,
        preset: newPreset
      });

      setConversation(currentConvo);
      setMessages(messages);
      console.log("setMessages");
      return;
    }

    if (!newPreset) {
      console.log("newPreset");
      return;
    }

    console.log("end");
    newConversation({}, newPreset);
  };

  const onChangePreset = (preset) => {
    setPresetModelVisible(true);
    setPreset(preset);
    console.log("onChangePreset");
  };

  const clearAllPresets = () => {
    deletePresetsMutation.mutate({ arg: {} });
  };

  const onDeletePreset = (preset) => {
    deletePresetsMutation.mutate({ arg: preset });
  };

  const icon = getIcon({
    size: 32,
    ...conversation,
    isCreatedByUser: false,
    error: false,
    button: true
  });



  const handleInputChange = (event) => {
    // Here you might have some logic to decide when to consider the value change as something that should disable the other components
    const shouldDisable = event.target.value === 'some condition';
    
    // Call the passed in onValueChange function
    onValueChange(shouldDisable);
  };

  return (
    <Dialog className="z-[100]">
      <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            id="new-conversation-menu"
            variant="outline"
            className={`group relative mb-[-12px] ml-0 mt-[-8px] items-center rounded-md border-0 p-1 outline-none focus:ring-0 focus:ring-offset-0 dark:data-[state=open]:bg-opacity-50 md:left-1 md:ml-[-12px] md:pl-1`}
          >
            {icon}
            <span className="max-w-0 overflow-hidden whitespace-nowrap px-0 text-slate-600 transition-all group-hover:max-w-[80px] group-hover:px-2 group-data-[state=open]:max-w-[80px] group-data-[state=open]:px-2 dark:text-slate-300">
              New Topic
            </span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="z-[100] w-96 dark:bg-gray-900"
          onCloseAutoFocus={(event) => event.preventDefault()}
        >
          {/* <DropdownMenuLabel
            className="cursor-pointer dark:text-gray-300"
            onClick={() => setShowEndpoints((prev) => !prev)}
          >
            {showEndpoints ? 'Hide ' : 'Show '} Endpoints
          </DropdownMenuLabel>
          <DropdownMenuSeparator /> */}
          {/* <DropdownMenuRadioGroup
            value={endpoint}
            onValueChange={onSelectEndpoint}
            className="flex flex-col gap-1 overflow-y-auto"
          >
            {showEndpoints &&
              (availableEndpoints.length ? (
                <EndpointItems
                  selectedEndpoint={endpoint}
                  endpoints={availableEndpoints}
                  onSelect={onSelectEndpoint}
                />
              ) : (
                <DropdownMenuLabel className="dark:text-gray-300">
                  No endpoint available.
                </DropdownMenuLabel>
              ))}
          </DropdownMenuRadioGroup> */}

          <div className="mt-2 w-full" />

          <DropdownMenuLabel className="flex items-center dark:text-gray-300">
            <span
              className="mr-auto cursor-pointer "
              onClick={() => setShowPresets((prev) => !prev)}
            >
              {showPresets ? 'Hide ' : 'Show '} Presets
            </span>
            {/* <FileUpload onFileSelected={onFileSelected} />
            <FileUpload onFileSelected={onFileSelected2} /> */}

            <Dialog>
              <DialogTrigger asChild>
                <label
                  htmlFor="file-upload"
                  className="mr-1 flex h-[32px] h-auto cursor-pointer  items-center rounded bg-transparent px-2 py-1 text-xs font-medium font-normal text-gray-600 transition-colors hover:bg-slate-200 hover:text-red-700 dark:bg-transparent dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-green-500"
                >
                  {/* <Button
                  type="button"
                  className="h-auto bg-transparent px-2 py-1 text-xs font-medium font-normal text-red-700 hover:bg-slate-200 hover:text-red-700 dark:bg-transparent dark:text-red-400 dark:hover:bg-gray-800 dark:hover:text-red-400"
                > */}
                  {/* <Trash2 className="mr-1 flex w-[22px] items-center stroke-1" />
                  Clear All */}
                  {/* </Button> */}
                </label>
              </DialogTrigger>
              <DialogTemplate
                title="Clear presets"
                description="Are you sure you want to clear all presets? This is irreversible."
                selection={{
                  selectHandler: clearAllPresets,
                  selectClasses: 'bg-red-600 hover:bg-red-700 dark:hover:bg-red-800 text-white',
                  selectText: 'Clear'
                }}
              />
            </Dialog>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuRadioGroup
            onValueChange={onSelectPreset}
            className={cn(
              'overflow-y-auto overflow-x-hidden',
              showEndpoints ? 'max-h-[210px]' : 'max-h-[315px]'
            )}
          >
            {(showPresets ? (<>

              <PresetItems
                presets={presets[0]}
                onSelect={onSelectPreset}
                onChangePreset={onChangePreset}
                onDeletePreset={onDeletePreset}
              />

              <hr></hr>
              <PresetItems2
                presets={presets[1]}
                onSelect={onSelectPreset}
                onChangePreset={onChangePreset}
                onDeletePreset={onDeletePreset}
              />
            </>

            ) : (
              <DropdownMenuLabel className="dark:text-gray-300">No preset yet.</DropdownMenuLabel>
            ))}
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <EditPresetDialog
        open={presetModelVisible}
        onOpenChange={setPresetModelVisible}
        preset={preset}
      />
    </Dialog>
  );
}
