import React from 'react';
import PresetItem from './PresetItem.jsx';

export default function PresetItems({ presets, onSelect, onChangePreset, onDeletePreset }) {
  console.log("1", presets);
  return (
    <>
      <PresetItem
        key={presets?.presetId ?? Math.random()}
        value={presets}
        onSelect={onSelect}
        onChangePreset={onChangePreset}
        onDeletePreset={onDeletePreset}
        preset={presets}
      />
    </>
  );
}
